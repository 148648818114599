import { fetchWrapperPortal } from "./fetch";

export const find = async () =>
  await fetchWrapperPortal({
    url: `moderation/find`,
    method: `GET`,
    addBearer: true
  });

export const setDeleteStatus = async (chatId, deleteFlag, userName) =>
  await fetchWrapperPortal({
    url: `moderation/setDeleteStatus`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify({ chatId, deleteFlag, userName })
  });
