import React, { createContext, useContext, useState, useEffect } from 'react';
import { adminAPI } from '../portal-apis';

const ThemeContext = createContext();

export const DEFAULT_APP_ID = '09c935f5-95f3-4c47-9ca8-5d90da6f455c';

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTheme = async () => {
      try {
        const subdomain = window.location.hostname.split('.')[0];
        
        // Check localStorage first
        const cachedTheme = localStorage.getItem(`theme_${subdomain}`);
        if (cachedTheme) {
          setTheme(JSON.parse(cachedTheme));
          setLoading(false);
          return;
        }

        // If no cached theme, fetch from API
        const loadedTheme = await adminAPI.getThemeBySubdomain(subdomain);
        // Cache the theme
        localStorage.setItem(`theme_${subdomain}`, JSON.stringify(loadedTheme));
        setTheme(loadedTheme);
      } catch (error) {
        console.error('Error loading theme:', error);
        setTheme({ app_id: DEFAULT_APP_ID });
      } finally {
        setLoading(false);
      }
    };

    loadTheme();
  }, []);

  const value = {
    theme,
    setTheme,
    loading,
    app_id: theme?.app_id || DEFAULT_APP_ID
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const useAppId = () => {
  const { app_id } = useTheme();
  return app_id;
}; 