import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

const enableSentry = true;

if (enableSentry) {
  Sentry.init({
    dsn: "https://7f85237df353da1abc36734ef8e30b58@o4506265170542592.ingest.sentry.io/4506265171525632",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: true,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['warn', 'error']
      }),
    ],
    enabled: true,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    release: process.env.REACT_APP_VERSION || 'development',
    attachStacktrace: true,
    normalizeDepth: 10,
    environment: process.env.REACT_APP_SUBDOMAIN ? process.env.REACT_APP_SUBDOMAIN.replace('.', '') : 'local'
  });

  Sentry.getCurrentScope().setLevel("warning");
}

// Use createRoot API for React 18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

//another comment
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
