import { setGlobal, resetGlobal } from "reactn";
import { fetchWrapperPortal } from "./fetch";
import { userAPI } from "utils/portal-apis";

function isGuestUser() {
  return localStorage.getItem("is_guest_user") === "true" || localStorage.getItem("is_guest_user") === true;
}

function onLoginResult(user, isGuestUser = false) {
  console.log("onLoginResult", user);
  if (user && user._id) {
    localStorage.setItem("access_token", user.tokens.access_token);
    localStorage.setItem("access_token_expires_at", user.tokens.access_token_expires_at);
    localStorage.setItem("refresh_token", user.refresh_token);
    localStorage.setItem("refresh_token_expires_at", user.tokens.refresh_token_expires_at);
    localStorage.setItem("is_guest_user", isGuestUser);
    console.log("localStorage", user);
  }
  userAPI.persistUser(user);
  return user ? user : false;
}

export const isLoggedIn = () => {
  if (isGuestUser()) {
    return false;
  }
  const exp = localStorage.getItem("access_token_expires_at");
  if (exp && exp >= new Date().getTime() / 1000) {
    const userObj = JSON.parse(localStorage.getItem("user"));
    setGlobal(userObj);
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["identify", { email: userObj.email }]);
    // eslint-disable-next-line no-unused-expressions
    window.HubSpotConversations?.widget?.refresh();
    return true;
  }
  console.log("User not logged in");
  return false;
};

export const isRefreshable = () => {
  if (isGuestUser()) {
    return false;
  }
  const exp = localStorage.getItem("refresh_token_expires_at");
  return exp && exp >= new Date().getTime() / 1000;
};

export const signup = async inputs => {
  const user = await userAPI.signup(inputs);
  console.log("signup", user);
  return onLoginResult(user);
};

export const login = async inputs => {
  const result = await fetchWrapperPortal({
    url: `auth/login/`,
    method: `POST`,
    body: JSON.stringify(inputs)
  });
  console.log("login", result);
  return onLoginResult(result);
};

export const oauthLogin = async (code) => {
  const result = await fetchWrapperPortal({
    url: `auth/oauth/login`,
    method: `POST`,
    body: JSON.stringify({ code })
  });
  console.log("oauthLogin result", result);
  return onLoginResult(result);
};

export const logout = async () => {
  resetGlobal();
  return localStorage.clear();
};

export const getExternalToken = async token => {
  const result = await fetchWrapperPortal({
    url: `users/me`,
    method: `GET`,
    addBearer: true,
    token: `Bearer ${token}`
  });
  const now = new Date();
  const exp = Math.round(now.setDate(now.getDate() + 1) / 1000);
  return onLoginResult({ ...result, tokens: { access_token: token, access_token_expires_at: exp } });
};

export const needsRefresh = () => {
  if (isGuestUser()) return false;
  
  const expiresAt = localStorage.getItem('access_token_expires_at');
  if (!expiresAt) return false;
  
  const expiresAtTimestamp = parseInt(expiresAt, 10);
  const currentTime = Math.floor(Date.now() / 1000);
  const twoHours = 2 * 60 * 60; // 2 hours in seconds
  
  return expiresAtTimestamp - currentTime < twoHours;
};

export const refreshAuth = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  const params = await fetchWrapperPortal({
    url: `auth/refresh/`,
    method: `POST`,
    headers: {
      Authorization: `Bearer ${refresh_token}`
    }
  });
  return onLoginResult(params);
};

export const getResetToken = async token =>
  await fetchWrapperPortal({
    url: `auth/password/reset/${token}`,
    method: `GET`,
    addBearer: false
  });
