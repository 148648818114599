import { fetchWrapperPortal } from "./fetch";

export const createTheme = async (themeData) => {
  return await fetchWrapperPortal({
    url: 'admin/themes',
    method: 'POST',
    headers: {
      'x-internal-secret': process.env.REACT_APP_INTERNAL_SECRET,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(themeData),
    addBearer: true,
  });
};

export const getThemes = async (query = {}) => {
  const queryString = new URLSearchParams(query).toString();
  return await fetchWrapperPortal({
    url: `admin/themes${queryString ? `?${queryString}` : ''}`,
    method: 'GET',
    headers: {
      'x-internal-secret': process.env.REACT_APP_INTERNAL_SECRET,
    },
    addBearer: true,
  });
};

export const getThemeBySubdomain = async (subdomain) => {
  return await fetchWrapperPortal({
    url: `admin/themes/${subdomain}`,
    method: 'GET',
    headers: {
      'x-internal-secret': process.env.REACT_APP_INTERNAL_SECRET,
    },
    addBearer: true,
  });
};

export const updateTheme = async (themeId, themeData) => {
  return await fetchWrapperPortal({
    url: `admin/themes/${themeId}`,
    method: 'PUT',
    headers: {
      'x-internal-secret': process.env.REACT_APP_INTERNAL_SECRET,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(themeData),
    addBearer: true,
  });
};

export const deleteTheme = async (themeId) => {
  return await fetchWrapperPortal({
    url: `admin/themes/${themeId}`,
    method: 'DELETE',
    headers: {
      'x-internal-secret': process.env.REACT_APP_INTERNAL_SECRET,
    },
    addBearer: true,
  });
};
