import React, { getGlobal, useGlobal } from "reactn";
import { Navigate, useLocation } from "react-router-dom"; // Removed Route import
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader } from "utils/global";
import { useTheme } from 'utils/global/ThemeContext';
import { authAPI, userAPI } from "utils/portal-apis";

import { defaultSettings, getMenuRoutes, RightContent } from "./common";
import { useEffect, useState } from "react";
import styled from "styled-components";

// Adjust the StyledProLayout styling if necessary for the top bar layout
const StyledProLayout = styled(ProLayout)`
  max-width: none !important;
  width: 100vw !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .ant-pro-top-nav-header {
    background: #000000 !important;
    border-bottom: 1px solid var(--border-default, #3D3C41);
  }

  .ant-menu {
    background: transparent !important;
  }

  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-item span {
    font-family: "Druk Text" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    color: #FFFFFF !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-submenu-title span {
    font-family: "Druk Text" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    color: #FFFFFF !important;
  }

  .ant-menu-item {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  .ant-menu-item-selected a {
    position: relative !important;
    
    &:after {
      content: '' !important;
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      bottom: -5px !important;
      height: 3px !important;
      background: #FFFFFF !important;
    }
  }

  .ant-menu-item::after {
    display: none !important;
  }
`;

function PrivateRoute({ children, noHeader = false }) {
  const { loading } = useGlobalLoader();
  const [authenticated, setAuthenticated] = useState(true);
  const [isLive] = useGlobal("isLive");
  const { theme } = useTheme();
  let location = useLocation();

  useEffect(() => {
    userAPI.refreshUser().then((result) => {
      setAuthenticated(!!result && !!result._id);
    });
  }, []);

  if (authenticated && authAPI.isLoggedIn()) {
    const user = getGlobal();
    if (user.show_portal_onboarding) {
      localStorage.setItem("redirectURL", location.pathname);
      return <Navigate to="/auth/onboarding" />;
    }
    return (
      <GlobalLoader loading={loading}>
        <StyledProLayout
          {...Object.assign(defaultSettings, { logo: theme?.logo?.primary, layout: 'top', headerRender: noHeader ? false : undefined })}
          route={isLive ? getMenuRoutes(true) : getMenuRoutes(false)}
          rightContentRender={RightContent}
          isPublicPage={false}
          isLive={isLive}
        >
          <Layout>
            <Layout.Content>
              {children}
            </Layout.Content>
          </Layout>
        </StyledProLayout>
      </GlobalLoader>
    );
  } else {
    localStorage.setItem("redirectURL", location.pathname);
    return <Navigate to="/auth/login" />;
  }
}

export default PrivateRoute;
