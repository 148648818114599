import React, { Suspense } from "reactn";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { PageLoading } from "@ant-design/pro-layout";

import { PublicRoute, PrivateRoute, AuthRoute } from "utils/routes";
import { LoaderStore } from "utils/global";
import { useEffect } from "react";
import { ConfigProvider, theme as antdTheme } from 'antd';
import enUS from 'antd/locale/en_US';
import { ThemeProvider as GlobalThemeProvider, useTheme } from './utils/global/ThemeContext';

// Auth Imports
const AuthLogin = React.lazy(() => import("pages/Auth/Login"));
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"));
const ResetWithToken = React.lazy(() => import("pages/Auth/ResetWithToken"));
const OnboardingMain = React.lazy(() => import("pages/Auth/OnboardingMain"));
const OnboardingFirstVisit = React.lazy(() => import("pages/Auth/OnboardingFirstVisit"));
const OAuthCallback = React.lazy(() => import("pages/Auth/OAuthCallback"));

// Settings
const Settings = React.lazy(() => import("pages/Settings/Settings"));

// Orgs
const Orgs = React.lazy(() => import("pages/Org/List"));

// Schedule
const ScheduleChannels = React.lazy(() => import("pages/Schedule/Channels"));

// Event
const EventSingle = React.lazy(() => import("pages/Event/Single"));
const EventActiveList = React.lazy(() => import("pages/Event/Active"));

// Broadcast
const BroadcastMy = React.lazy(() => import("pages/Dashboard/My"));
const BroadcastSingle = React.lazy(() => import("pages/Broadcast/Page"));

// Experience Composer Page
const ComposerPage = React.lazy(() => import("pages/Broadcast/ComposerPage"));

// Watch
const WatchSingle = React.lazy(() => import("pages/Watch/Single"));

// Orgs
const OrgList = React.lazy(() => import("pages/Orgs"));

// Orgs
const UserList = React.lazy(() => import("pages/User/List"));
const Moderation = React.lazy(() => import("pages/Moderation"));
const ModerationList = React.lazy(() => import("pages/Moderation/List"));
const ThemeEditor = React.lazy(() => import("pages/Theme/ThemeEditor"));

// Webhooks
const Webhooks = React.lazy(() => import("pages/Webhooks"));

// Web Embed
const WebEmbed = React.lazy(() => import("pages/WebEmbed"));

// Uploaded Videos CRUD
const Media = React.lazy(() => import("components/Media"));
const ViewUploadedMedia = React.lazy(() => import("components/Media/View"));

// Exception Imports
const Exception404 = React.lazy(() => import("pages/Exception/404"));

// Add this new import for the ClipsPage
const ClipsPage = React.lazy(() => import("pages/Clips"));

// Helper component to handle theme-dependent configuration
function SuspendedApp({ children }) {
  const { loading, theme } = useTheme();

  if (loading || !theme) {
    return <PageLoading />;
  }

  return children;
}

function ThemedApp() {
  const { theme: currentTheme } = useTheme();
  
  // Convert our theme to Ant Design token format
  // This changes default Antd components appearance
  const convertThemeToAntd = (theme) => ({
    // Colors
    colorPrimary: theme?.colors?.primary || '#6927f4',
    colorHighlight: theme?.colors?.highlight || '#ff4d4f',

    // Fonts
    fontFamily: theme?.typography?.fontFamily?.primary || '"Proxima Nova", Helvetica, Arial, sans-serif',
    fontSize: parseInt(theme?.typography?.fontSize?.small || '14'),
    
    // Additional Ant Design specific values
    colorSuccess: theme?.colors?.success || '#52c41a',
    colorWarning: theme?.colors?.warning || '#faad14',
    colorError: theme?.colors?.error || '#f5222d',
    colorLink: theme?.colors?.link || '#1890ff',
    
    // Text colors
    colorText: theme?.colors?.text?.primary || 'rgba(255, 255, 255, 0.85)',
    colorTextBase: theme?.colors?.text?.primary || '#ffffff',
    colorTextSecondary: theme?.colors?.text?.secondary || 'rgba(255, 255, 255, 0.65)',
    colorTextHeading: theme?.colors?.text?.heading || 'rgba(255, 255, 255, 0.85)',
    colorTextDisabled: theme?.colors?.disabled || 'rgba(255, 255, 255, 0.25)',
    
    // Layout colors
    colorBgLayout: theme?.colors?.background?.gradient?.[1] || '#000000',
    colorBgContainer: theme?.colors?.background?.gradient?.[0] || '#000000',
    
    // Menu colors
    colorBgMenu: theme?.colors?.background?.primary || '#0A0A0A',
    colorItemBgHover: theme?.colors?.background?.hover || '#2c2c2f',
    
    // Border
    borderColorBase: theme?.colors?.border || '#d9d9d9',
    borderRadius: parseInt(theme?.layout?.borderRadius || '8'),
    borderRadiusBase: parseInt(theme?.layout?.borderRadiusBase || '4'),
    
    // Box shadow
    boxShadowBase: theme?.layout?.boxShadow ||'0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        
    // Body background
    colorBgBase: theme?.colors?.background?.modal || '#000',
    colorBgBody: theme?.colors?.background?.secondary || '#f0f2f5',
  });
  
  useEffect(() => {
    if (currentTheme) {
      // Convert theme values to Less variables if needed
      const lessVars = Object.keys(currentTheme).reduce((acc, key) => {
        if (typeof currentTheme[key] === 'string') {
          acc[`@${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`] = currentTheme[key];
        }
        return acc;
      }, {});

      window.less
        ?.modifyVars(lessVars)
        .catch(e => console.log("Error updating default theme", e));
      }
      
  }, [currentTheme]);

  // Get subdomain from window.location
  const subdomain = window.location.hostname.split('.')[0];

  return (
    <ConfigProvider 
      locale={enUS} 
      theme={{
        algorithm: antdTheme.darkAlgorithm,
        token: convertThemeToAntd(currentTheme)
      }}
    >
      <SuspendedApp>
        <Router>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              {/* Root */}
              <Route path="/" exact element={<Navigate to="/broadcast/my" replace />} />

              {/* Auth */}
              <Route path="/auth" element={<Navigate to="/auth/login" replace />} />

              <Route path="/auth/login" element={<AuthRoute><AuthLogin /></AuthRoute>} />
              <Route path="/auth/reset" element={<AuthRoute><ResetPassword /></AuthRoute>} />
              <Route path="/auth/reset/:token" element={<AuthRoute><ResetWithToken /></AuthRoute>} />
              <Route path="/auth/onboarding" element={<AuthRoute><OnboardingMain /></AuthRoute>} />
              <Route path="/auth/onboarding/:token" element={<AuthRoute><OnboardingFirstVisit /></AuthRoute>} />
              <Route path="/login/oauth/callback" element={<AuthRoute><OAuthCallback /></AuthRoute>} />

              {/* Settings */}
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />

              {/* Developer */}
              <Route path="/webhooks" element={<PrivateRoute><Webhooks /></PrivateRoute>} />
              <Route path="/web-embed" element={<PrivateRoute><WebEmbed /></PrivateRoute>} />

              {/* Event */}
              <Route path="/event" element={<PrivateRoute><Navigate to="/schedule/events" replace /></PrivateRoute>} />
              <Route path="/event/active" element={<PrivateRoute><EventActiveList /></PrivateRoute>} />
              <Route path="/event/:event" element={<PrivateRoute><EventSingle /></PrivateRoute>} />

              {/* Broadcast/Stream */}
              <Route path="/broadcast" element={<Navigate to="/schedule/events" replace />} />
              <Route path="/broadcast/my" element={<PrivateRoute><BroadcastMy /></PrivateRoute>} />
              <Route path="/broadcast/:stream" element={<PrivateRoute noHeader={true}><BroadcastSingle /></PrivateRoute>} />

              {/* Experience Composer Page */}
              <Route path="/ec" element={<PublicRoute ecPage={true}><ComposerPage /></PublicRoute>} />

              {/* Watch Page */}
              <Route path="/watch/:stream" element={<PublicRoute><WatchSingle /></PublicRoute>} />

              {/* Orgs */}
              <Route path="/orgs/list" element={<PrivateRoute><OrgList /></PrivateRoute>} />

              {/* Hosts */}
              <Route path="/user/list" element={<PrivateRoute><UserList /></PrivateRoute>} />
              <Route path="/moderation" element={<PrivateRoute><Moderation /></PrivateRoute>} />
              <Route path="/moderation/list" element={<PrivateRoute><ModerationList /></PrivateRoute>} />
              <Route path="/theme" element={<PrivateRoute><ThemeEditor /></PrivateRoute>} />

              {/* Uploaded Videos */}
              <Route path="/media" element={<PrivateRoute><Media /></PrivateRoute>} />
              <Route path="/videos/:id" element={<PrivateRoute><ViewUploadedMedia type="video"/></PrivateRoute>} />
              <Route path="/images/:id" element={<PrivateRoute><ViewUploadedMedia type="image"/></PrivateRoute>} />
              <Route path="/overlays/:id" element={<PrivateRoute><ViewUploadedMedia type="overlay"/></PrivateRoute>} />
              <Route path="/backgrounds/:id" element={<PrivateRoute><ViewUploadedMedia type="background"/></PrivateRoute>} />

              {/* Exceptions */}
              <Route path="*" element={<PublicRoute><Exception404 /></PublicRoute>} />

              {/* Add this new route for the Clips page */}
              <Route path="/clips" element={<PrivateRoute><ClipsPage /></PrivateRoute>} />

            </Routes>
          </Suspense>
        </Router>
      </SuspendedApp>
    </ConfigProvider>
  );
}

function App() {
  return (
    <LoaderStore>
      <GlobalThemeProvider>
        <ThemedApp />
      </GlobalThemeProvider>
    </LoaderStore>
  );
}

export default App;

