import React, { useState } from "reactn";
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader } from "utils/global";
import { useTheme } from 'utils/global/ThemeContext';
import { authAPI } from "utils/portal-apis"; // TODO

import { publicDefaultSettings, RightContent } from "./common";

function PublicRoute({ children, ecPage }) {
  const [showSignIn, setShowSignIn] = useState(false);

  const { loading } = useGlobalLoader();
  const { theme } = useTheme();

  if (authAPI.isLoggedIn() && showSignIn) {
    console.log("User is logged in but accessing public page");
    setShowSignIn(false);
  }

  if (ecPage) {
    return children;
  }

  return (
    <GlobalLoader loading={loading}>
      <ProLayout
        {...Object.assign(publicDefaultSettings, { logo: theme?.logo.primary })}
        rightContentRender={RightContent}
        isPublicPage={true}
        showSignIn={showSignIn}
        signInSetter={v => setShowSignIn(v)}
        style={{ width: '100%', maxWidth: '100%' }} // Ensure ProLayout expands fully
      >
        <Layout style={{ width: '100%', maxWidth: '100%' }}> {/* Ensure outer Layout expands fully */}
          <Layout.Content style={{ margin: "-28px -20px -24px", width: '100%' }}> {/* Ensure Content expands fully */}
            {children}
          </Layout.Content>
        </Layout>
      </ProLayout>
    </GlobalLoader>
  );
}

export default PublicRoute;