import React, { useContext, createContext, useState } from "reactn";
import GlobalLoader from "components/GlobalLoader";
import { systemAPI } from "utils/portal-apis";

const LoadingContext = createContext({});

export { GlobalLoader };

export const useGlobalLoader = () => {
  return useContext(LoadingContext);
};

export const LoaderStore = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const ThemeContext = createContext({
  content: {
    modals: {
      streamSetup: {
        title: 'Stream Setup',
        videoProvideQuestion: 'How would you like to provide video?',
        easyMode: 'Right here on this page (Easy).',
        advancedMode: 'Other streaming software, such as OBS (Advanced).',
        rtmpInstructions: 'Copy the RTMP settings below to your streaming software of choice.',
        rtmpWarning: 'The host of this stream has selected RTMP publishing mode.',
        streamingPlatform: 'Streaming to another platform?',
        rtmpDestination: 'RTMP Destination',
        rtmpTooltip: 'You can send your stream to other platforms.',
        previewWarning: 'We will start sending video for preview.'
      },
      createStream: {
        title: 'Create a Live Stream',
        success: 'Stream Created!',
        appMessage: 'Your event has been added to the app!'
      }
    }
  }
});
export const useGlobalTheme = () => {
  return useContext(ThemeContext);
};

export const checkVersion = async version => {
  console.log("Checking frontend version from database...");
  const dbVersion = await systemAPI.getFrontendVersion();

  if (!dbVersion) {
    console.log("Database ERROR. couldn't get frontend version information for cache control");
  } else {
    console.log("version from database is:", dbVersion);

    if (dbVersion !== version) {
      alert("There is an updated version of HotMic update available, this page will reload.");
      window.location.reload(true);
    } else {
      console.log("The frontend version is up-to-date.");
    }
  }
};
